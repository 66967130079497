import React, { useEffect, useState } from 'react';
import { getAngularService } from 'ReactAngular/react.service';
import { change, Field } from 'redux-form';
import { ReduxFormTextAreaField } from '~/components/src/Form/Fields/ReduxFormFields';
import { defaultMaxInput, isEmails, isRequired, useAPI } from '~/common';
import { FormSection } from '~/components/src/Containers';

const isValidEmails = values => {
  const emails = values.replace(/,+/g, ',').split(',');
  return isEmails(emails.map(item => item.trim()));
};

const ExportNotifyForm = props => {
  const [isInitialized, setIsInitialized] = useState(false);
  const {
    data: { t, initialize, formValues, dispatch, form },
  } = props;
  const SecurityService = getAngularService(document, 'SecurityService');
  const { data: context } = useAPI(async () => {
    const securityContext = await SecurityService.getSecurityContext();
    return {
      email: securityContext?.getUser()?.username,
    };
  });

  useEffect(() => {
    if (context?.email && !isInitialized) {
      const email = formValues?.notify?.email ?? context.email;
      dispatch(change(form, 'notify.email', email));
      setIsInitialized(true);
    }
  }, [context, formValues, initialize, isInitialized]);

  return (
    <FormSection title={t('new.notify.header')} description={t('new.notify.subHeader')}>
      <Field
        label={t('new.notify.email.label')}
        name="email"
        placeholder={t('new.notify.email.placeholder')}
        component={ReduxFormTextAreaField}
        validate={[isRequired, defaultMaxInput, isValidEmails]}
        type="text"
      />
    </FormSection>
  );
};

export default ExportNotifyForm;
