/* eslint-disable quotes */
angular
  .module('webUi.common.Utils', ['app-templates', 'component-templates', 'restangular'])

  .provider('Utils', function UtilsProvider() {
    var SPECIAL_CHARS = [
      '\\',
      '^',
      '$',
      '{',
      '}',
      '[',
      ']',
      '(',
      ')',
      '.',
      '+',
      '?',
      '|',
      '-',
      '&',
      'd',
      'D',
      'l',
      't',
      's',
      'S',
      'w',
      'W',
      'B',
      'n',
      'r',
      'b',
      '/',
    ];

    _.mixin({
      capitalize: function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      },

      capitalizeWithCamelCase: function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
    });

    /**
     * @param str
     * @returns {string}
     */
    var escapeHtml = function escapeHtml(str) {
      var entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
      };
      return String(str).replace(/[&<>"'/]/g, function fromEntityMap(s) {
        return entityMap[s];
      });
    };

    var getShallowPropertyName = function (objName, fieldName) {
      if (!_.contains(fieldName, objName)) {
        throw new Error('Wrong arguments provided: ' + objName + ' and ' + fieldName);
      }
      return fieldName.split(objName + '.')[1];
    };

    var markResponseAsHandled = function markResponseAsHandled(response) {
      response.errorHandled = true;
      return response;
    };

    var isPromise = function (object) {
      return !_.isUndefined(object) && !_.isNull(object) && typeof object.then === 'function';
    };

    var recursiveThen = function (obj, func) {
      if (isPromise(obj)) {
        obj.then(function (resolvedObject) {
          recursiveThen(resolvedObject, func);
        });
      } else {
        func(obj);
      }
    };

    var findValue = function (object, pathParts, mandatory, recursive) {
      function returnValue(child, childName, pathParts, mandatory, recursive) {
        // Could not find the element?
        if (_.isNull(child) || _.isUndefined(child)) {
          if (mandatory) {
            throw new Error("Couldn't find element: '" + childName + "'.");
          } else {
            return null;
          }
        } else {
          if (pathParts.length === 0) {
            // Last step. Return it
            return child;
          } else {
            // Not last step: go inside object
            return findValue(child, pathParts, mandatory, recursive);
          }
        }
      }

      if (isPromise(object)) {
        // This is a promise. Wait till it's resolved. Return the promise for now.
        return object.then(function (resolvedObject) {
          // Object resolved. Retry to call the function.
          return findValue(resolvedObject, pathParts, mandatory, recursive);
        });
      }

      var childName = pathParts[0];
      var child;
      //it can be "criterion" or "criteria[0]"
      if (childName.indexOf('[') > -1) {
        var prop = childName.substring(0, childName.indexOf('['));
        var idx = childName.substring(childName.indexOf('[') + 1, childName.indexOf(']'));
        child = object[prop][idx];
      } else {
        child = object[childName];
      }
      if ((_.isNull(child) || _.isUndefined(child)) && recursive && object.$parent) {
        return findValue(object.$parent, pathParts, mandatory, recursive);
      }

      pathParts.shift();

      if (isPromise(child)) {
        // This is a promise. Wait till it's resolved. Return the promise for now.
        return child.then(function (resolvedChild) {
          // Object resolved. Now call the function again.
          return returnValue(resolvedChild, childName, pathParts, mandatory, recursive);
        });
      }

      return returnValue(child, childName, pathParts, mandatory, recursive);
    };

    var findModifierFunctionNames = function (object) {
      var regexes = '^delete(.*)|^update(.*)|^insert(.*)|(^create(.*))|(^save(.*))|(^add(.*))';
      var interestingProps = [];
      _.forOwn(object, function (num, key) {
        if (key.match(regexes) && _.isFunction(object[key])) {
          interestingProps.push(key);
        }
      });
      return interestingProps;
    };

    var wrapModifierFunctions = function (object, callback) {
      var functs = findModifierFunctionNames(object);
      _.forEach(functs, function (functionName) {
        wrapFunction(object, functionName, callback);
      });
    };

    var wrapFunction = function (object, functionName, callback, before) {
      var functionToWrapAround = object[functionName];
      if (!_.isFunction(functionToWrapAround)) {
        throw new Error("provided object doesn't have a function named: " + functionName);
      }
      object[functionName] = function () {
        if (_.isFunction(before)) {
          before.apply(this, arguments);
        }

        var res = functionToWrapAround.apply(this, arguments);

        if (_.isFunction(callback)) {
          if (isPromise(res)) {
            res.then(callback);
          } else {
            callback.apply(this, arguments);
          }
        }
        return res;
      };
    };
    
    var escapeCsvValue = function(value) {
      if (_.isString(value) && ['=', '+', '-', '@'].includes(value[0])) {
        return `'${value}`; // Prefix with a single quote
      }
      return value;
    };

    var utils = {
      SPECIAL_CHARS: SPECIAL_CHARS,
      getRestPropertyChangeURL: getShallowPropertyName,
      /**
       * Wraps all modifier functions (starting with 'set', 'update', 'create', 'insert', 'save', 'add') with the provided callback
       * @param {Service} object the object (normally a service) which functions are to be inspected and wrapped with the specified callback
       * @param {Function} callback the function to be executed after the modifier function has been executed (takes care of promise or normal functions)
       * @see findModifierFunctionNames
       */
      wrapModifierFunctions: wrapModifierFunctions,
      /**
       * Wraps an object's function with a before or after (or both callbacks)
       * @param {type} object
       * @param {String} functionName the name of the function to be wrapped
       * @param {Function} callback the callback to be executed after the original function
       * @param {Function} before the function to be executed before the original function
       */
      wrapFunction: wrapFunction,
      findModifierFunctionNames: findModifierFunctionNames,
      escapeCsvValue: escapeCsvValue,
      watchChanged: function ($scope, variableName, callback) {
        $scope.$watch(
          variableName,
          function (newValue, oldValue) {
            if (!_.isEqual(newValue, oldValue) && !(_.isNull(oldValue) && !_.isNull(newValue))) {
              callback(newValue, oldValue);
            }
          },
          true,
        );
      },
      /**
       * If for some reason, you want to use a resolved promise, you can force it using this function. Angular models/controllers and templates handle promises
       * transparently, so probably this won't be needed
       * @param {type} promisepromise
       * @param {$q} q
       * @param {$scope} scope
       * @param {any} value the value with which the then will be called
       * @returns {undefined}
       */
      resolvePromise: function (promise, q, scope) {
        var defer = q.defer();
        var unproxiedPromise;
        promise.then(function (value) {
          unproxiedPromise = value;
        });
        defer.resolve();
        scope.$apply();
        return unproxiedPromise;
      },
      applyIfPossible: function ($scope) {
        if (!$scope.$$phase) {
          $scope.$apply();
        }
      },
      getScopeValue: function ($scope, fullPath, mandatory, recursive) {
        var poundIdx = fullPath.indexOf('#');
        if (poundIdx !== -1) {
          fullPath = fullPath.substring(0, poundIdx);
        }

        try {
          return findValue($scope, fullPath.split('.'), mandatory, recursive);
        } catch (e) {
          e.message += ' Path we were looking for was: ' + fullPath;
          throw e;
        }
      },

      setScopeValue: function ($scope, fullPath, value, recursive) {
        if (fullPath.indexOf('.') === -1) {
          // Throw exception because we need to set by reference. Otherwise it creates it's own copy and changes the copy.
          throw new Error("Path should contain at least one dot (e.g. tag.location). You provided '" + fullPath + "'");
        }

        // Split fullpath into parentPath and lastPathElement.
        // E.g. fullPath = "tag.location.bla", then:
        //		parentPath = $scope.tag.location and
        //		lastPathElement = "bla"
        var parentPathElements = fullPath.split('.');
        var lastPathElement = parentPathElements.pop();

        try {
          var parentValue = findValue($scope, parentPathElements, true, recursive);
          //the object on which we set the new value is sometimes a promise
          if (isPromise(parentValue)) {
            parentValue.then(function (parent) {
              parent[lastPathElement] = value;
            });
          } else {
            //also treat the case where the object is not a promise
            parentValue[lastPathElement] = value;
          }
        } catch (e) {
          e.message += ' Path we were looking for was: ' + fullPath;
          throw e;
        }
      },

      /**
       * @description: Parser for tree nodes
       *
       * @private
       * @param baseSref
       * @param srefId
       * @param resultsToParse
       * @param nodeInitCallbackFn
       * @param nodeDeleteCallbackFn
       * @param level
       * @param parent
       * @returns {Array}
       */
      parseNodes: function (
        resultsToParse,
        baseSrefs,
        srefIds,
        nodeInitCallbackFn,
        nodeDeleteCallbackFn,
        level,
        parent,
      ) {
        var nodes = [];
        var self = this;
        if (_.isUndefined(resultsToParse)) {
          return nodes;
        }

        level = level || 0;

        // convert baseSrefs / srefIds to arrays
        baseSrefs = _.isArray(baseSrefs) ? baseSrefs : [baseSrefs];
        srefIds = _.isArray(srefIds) ? srefIds : [srefIds];

        // determine baseSref / srefId for current level
        var currentBaseSref = baseSrefs.length > level ? baseSrefs[level] : _.last(baseSrefs);
        var currentSrefId = srefIds.length > level ? srefIds[level] : _.last(srefIds);

        _.forEach(resultsToParse, function (nodeObj) {
          var currentNode = _.cloneDeep(nodeObj);

          currentNode.parent = parent;

          // trigger callback
          if (_.isFunction(nodeInitCallbackFn)) {
            nodeInitCallbackFn(currentNode, nodeObj, level);
          }

          if (currentBaseSref) {
            var currentNodeSrefId = parent && parent.srefId ? _.clone(parent.srefId) : {};

            if (_.isArray(currentSrefId)) {
              currentNodeSrefId[currentSrefId[1]] = nodeObj[currentSrefId[0]];
            } else {
              currentNodeSrefId[currentSrefId] = nodeObj.id;
            }

            currentNode.srefId = currentNodeSrefId;
            currentNode.sref = currentBaseSref + '(' + JSON.stringify(currentNodeSrefId) + ')';
            currentNode.onRemove = nodeDeleteCallbackFn;
          }

          currentNode.children = self.parseNodes(
            nodeObj.children,
            baseSrefs,
            srefIds,
            nodeInitCallbackFn,
            nodeDeleteCallbackFn,
            level + 1,
            currentNode,
          );
          nodes.push(currentNode);
        });

        return nodes;
      },

      isPromise: isPromise,
      recursiveThen: recursiveThen,
      attrPrefix: 'options',

      /**
       * TODO test this function and always have it return the same type of result
       * From an array, it creates an object with a key that's determined from the object.
       * Each entry in the array will become a property of the object. The name of the property is specified by the user and the value is the element
       */
      objectMap: function (objects, key) {
        var resultArray = {};
        _.each(objects, function (object) {
          resultArray[object[key]] = object;
        });
        return resultArray;
      },
      /**
       * Dirty one individual form field if it's pristine, but invalid
       */
      setFormFieldDirty: function (field) {
        // checking if it is pristine and not a '$' special field
        if (field[0] !== '$' && field.$pristine && field.$invalid) {
          field.$dirty = true;
          field.$pristine = false;
        }
      },

      /**
       * Take an angular form and check for fields that are pristine, but invalid, and set them to dirty.
       * Used for invalidating a form that's being submitted without being modified
       */
      setAllFormFieldsDirty: function (form) {
        for (var field in form) {
          // look at each form input with a name attribute set
          // checking if it is pristine and not a '$' special field
          if (field[0] !== '$' && form[field].$pristine && form[field].$invalid) {
            form[field].$dirty = true;
            form[field].$pristine = false;
          }
        }
      },
      // copy from https://github.com/angular/angular.js/blob/master/src/Angular.js
      camelToDash: function (str) {
        var SNAKE_CASE_REGEXP = /[A-Z]/g;
        return str.replace(SNAKE_CASE_REGEXP, function (letter, pos) {
          return (pos ? '-' : '') + letter.toLowerCase();
        });
      },
      dashToCamel: function (str) {
        var SPECIAL_CHARS_REGEXP = /([:\-_]+(.))/g;
        var MOZ_HACK_REGEXP = /^moz([A-Z])/;
        return str
          .replace(SPECIAL_CHARS_REGEXP, function (_, separator, letter, offset) {
            return offset ? letter.toUpperCase() : letter;
          })
          .replace(MOZ_HACK_REGEXP, 'Moz$1');
      },
      /**
       * Detects IE version
       * @param ieVersion
       * @returns {*}
       */
      detectIe: function (ieVersion) {
        var ieBrowsers = {};
        ieBrowsers.ie9 = /MSIE 9/i.test(navigator.userAgent);
        ieBrowsers.ie10 = /MSIE 10/i.test(navigator.userAgent);
        ieBrowsers.ie11 = /rv:11.0/i.test(navigator.userAgent);
        if (ieVersion && ieBrowsers.ieVersion) {
          return true;
        }
        return ieBrowsers.ie9 || ieBrowsers.ie10 || ieBrowsers.ie11;
      },
      /**
       * Marks a http response (generally an error response) as handled. That is generally invoked by an onError callback of a promise
       * to signify that it is done with handling the error and a more-general error shouldn't be shown to the user
       * @param {HttpRequestResponse} response (http response, as received by Restangular)
       * @return {HttpRequestResponse} the same request, but marked as handled
       * @see Restangular#addResponseInterceptor
       * @see https://synovite.atlassian.net/browse/RP-1961
       */
      markResponseAsHandled: markResponseAsHandled,

      /**
       * Escapes HTML (moustache version)
       */
      escapeHtml: escapeHtml,
      /**
       * Get and Event Draggable mock and set a dataMock to check the transformations
       * @param type
       * @param dataMock
       * @returns EventMock with original Event
       * */
      eventsDraggableMock: function (type, dataMock) {
        return {
          type: type,
          originalEvent: {
            target: {
              draggable: true,
            },
            dataTransfer: {
              setDragImage: function (el, x, y) {
                dataMock.setDragImageValues = {
                  name: el.textContent,
                  x: x,
                  y: y,
                };
              },
              setData: function (_keyTypeDragEvent, id) {
                dataMock.id = id;
              },
              getData: function () {
                return dataMock.id;
              },
              items: [{ kind: 'string', type: 'draggableid' }],
            },
          },
        };
      },

      /**
       * Get the timestamp of the start of the day one week before today
       * */
      getStartOfTheDayOneWeekInThePast: function () {
        return moment().subtract(7, 'days').startOf('day').toDate().getTime();
      },

      /**
       * Get the timestamp when the current day will end
       * */
      getEndOfToday: function () {
        return moment().endOf('day').toDate().getTime();
      },
    };

    return {
      /**
       * Retrieves the Utils before they have all their dependencies injected upon them
       * @returns {Utils}
       */
      get: function () {
        return utils;
      },
      $get: [
        '$q',
        'Restangular',
        function Utils($q, Restangular) {
          /**
           * @deprecated
           * @param item
           * @returns {*}
           */
          var sanitizeRestangularOne = function sanitizeRestangularOne(item) {
            return Restangular.stripRestangular(item);
          };
          return angular.extend(utils, {
            modalPromise: function (modalInstance) {
              var deferredLocation = $q.defer();
              modalInstance.result.then(function (result) {
                if (result) {
                  deferredLocation.resolve(result);
                }
              });
              return deferredLocation.promise;
            },
            sanitizeRestangularOne: sanitizeRestangularOne,
            /**
             * @deprecated
             * @param items
             * @returns {Array}
             */
            sanitizeRestangularAll: function (items) {
              for (var i = 0; i < items.length; i++) {
                items[i] = sanitizeRestangularOne(items[i]);
              }
              var arr = [];
              var sanitized = sanitizeRestangularOne(items);
              for (var prop in sanitized) {
                arr.push(sanitized[prop]);
              }
              return arr;
            },
          });
        },
      ],
    };
  });
