import { RulesMap } from '~/common/modules/rules/selectors';
import { getSiteId } from '~/common/SecurityMetaService';
import { getTitleAndTypeLabel, getVariablesUsedInRules } from '~/profiles/audiences/audienceUtils';
import { getConnectorImage } from '~/profiles/connectors';
import { ActivePartnerResponseItem } from '~/workflows/types';
import { onlyStepConnectors } from '~/workflows/util';
import { VariableModification } from '../types';

export const makeIntegration = (
  partnerName: string,
  partnerDetails: any,
  supportsEventListeners: any,
  activated = true,
  hasError = false,
) => {
  const imageUrl = getConnectorImage(getSiteId())(partnerDetails);

  return {
    activated,
    partnerName,
    partnerDetails,
    image: imageUrl,
    hasError,
    supportsEventListeners,
  };
};

export const getUsedVariables = (allVariables: any[], flatCriteria: any) => {
  const usedVarIds = getVariablesUsedInRules(flatCriteria);
  return usedVarIds.map(varId => ({
    profileVarId: varId,
    name: allVariables.find(variable => variable.variableId === varId).name,
  }));
};

export const getUsedVariablesForJO = (allVariables: any[] = []) =>
  allVariables.map(variable => ({
    variableType: variable.variableType,
    variableId: variable.variableId,
    name: variable.name,
  }));

export const getIntegrations = (integrations: any[]) =>
  integrations.map(integration => {
    const supportedChangeTypes = integration.supportedChangeTypes || [];
    const supportsEventListeners = supportedChangeTypes.includes('MODIFIED');

    return makeIntegration(integration.name, integration.partnerDetails, supportsEventListeners, integration.activated);
  });

export const getRuleTypeByCapitalizedValue = (capitalizedTypeValue: string) =>
  Object.entries(RulesMap).find(([, value]) => value.ruleTypeName === capitalizedTypeValue)?.[0];

export const mapVariableModifications = (variableModifications: VariableModification[] = []) =>
  variableModifications.map(variable => {
    const type = getRuleTypeByCapitalizedValue(variable.ruleType.name);
    const { title, typeLabel } = getTitleAndTypeLabel(type, variable.ruleName);

    return {
      id: variable.variableModificationId,
      ruleDefinitionId: variable.ruleDefinitionId,
      title,
      typeLabel,
      type,
      filters: variable.properties.map((property: any) => ({
        dataField: property.propertyName,
        profileVarId: property.variableId,
        profileVarName: property.variableName,
      })),
    };
  });

export const mappedTransformedConnectors = (allConnectors: ActivePartnerResponseItem[] = []) =>
  onlyStepConnectors(allConnectors).map(connector => {
    const { partnerDetails } = connector;

    const supportedChangeTypes = connector.supportedChangeTypes || [];
    const supportsEventListeners = supportedChangeTypes.includes('MODIFIED');

    const imageUrl = getConnectorImage(getSiteId())(partnerDetails);

    return {
      activated: connector.activated,
      partnerType: partnerDetails.partnerType,
      partnerName: connector.name,
      partnerId: partnerDetails.partnerId,
      partnerNumber: partnerDetails.partnerNumber,
      image: imageUrl,
      supportsEventListeners,
    };
  });
