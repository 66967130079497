const escapeCsvValue = (value: string): string => {
  if (typeof value === 'string' && ['=', '+', '-', '@'].includes(value[0])) {
    return `'${value}`; // Prefix with a single quote
  }
  return value;
};

export function convertToCSV(objArray: string): string {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  array.forEach((element: any) => {
    let line = '';
    Object.values(element).forEach(val => {
      if (line !== '') line += ',';
      line += escapeCsvValue(String(val));
    });
    str += `${line}\r\n`;
  });

  return str;
}
