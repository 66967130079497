import React, { ReactElement, useEffect } from 'react';

import cx from 'classnames';
import { Field, FieldArray, FieldArrayRenderProps, FormikErrors, getIn, useFormikContext } from 'formik';

import i18n from '~/i18n';
import BtnIcon from '~/components/src/BtnIcon';
import BtnOutlined from '~/components/src/BtnOutlined';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';

import { Model, ModelProperty, OptionType, Rule } from '../types';
import { NUMERIC_CONSTRAINTS, STRING_CONSTRAINTS } from '../constants';

import './styles.scss';

type RuleFormProps = {
  index: number;
  criteriaIndex: number;
  criteriaHelpers: FieldArrayRenderProps;
  rules: Rule[];
  errors: FormikErrors<Model>;
  properties: ModelProperty[];
};

function RuleForm({ index, criteriaIndex, criteriaHelpers, rules, errors, properties }: RuleFormProps): ReactElement {
  const { setFieldValue } = useFormikContext();
  const ruleErrors = getIn(errors, `predictions[${index}].criterias.[${criteriaIndex}].rules`) || [];

  const setPropertyFields = (ruleIndex: number, option: ModelProperty | undefined) => {
    const rulePath = `predictions[${index}].criterias[${criteriaIndex}].rules[${ruleIndex}]`;
    const rule = rules[ruleIndex];

    if (!option) {
      setFieldValue(`${rulePath}.property`, []);
      setFieldValue(`${rulePath}.config`, '');
      setFieldValue(`${rulePath}.constraint`, []);
      setFieldValue(`${rulePath}.filterValue`, []);
    } else {
      const { isNumeric, stats = { min: 0, max: 0 }, value, values } = option;
      const constraintValues = isNumeric ? NUMERIC_CONSTRAINTS : STRING_CONSTRAINTS;

      const config = {
        isNumeric,
        constraintValues,
        min: stats.min,
        max: stats.max,
        filterValues:
          !isNumeric && Array.isArray(values) ? values.map((value: string) => ({ label: value, value })) : [],
      };

      // Check if it is a custom value and is present in metadata
      if (
        !isNumeric &&
        Array.isArray(values) &&
        rule.filterValue &&
        !values.find(value => value === rule.filterValue)
      )
        config.filterValues.push({ label: rule.filterValue, value: rule.filterValue });

      setFieldValue(`${rulePath}.property`, value);
      setFieldValue(`${rulePath}.constraint`, rule.constraint);
      setFieldValue(`${rulePath}.config`, config);
    }
  };

  useEffect(() => {
    if (rules) {
      rules.forEach((rule, index) => {
        const property = properties.find(property => property.name === rule.property);
        setPropertyFields(index, property);
      });
    }
  }, []);

  const deleteRule = (ruleHelpers: FieldArrayRenderProps, index: number) => {
    ruleHelpers.remove(index);
    const isDeletingLastRule = rules.length === 1;
    if (isDeletingLastRule) {
      criteriaHelpers.remove(criteriaIndex);
    }
  };

  return (
    <FieldArray
      name={`predictions[${index}].criterias[${criteriaIndex}].rules`}
      render={ruleHelpers => (
        <div className={cx('Rules', { 'Rules-multiple': rules?.length > 1 })}>
          {rules?.map((rule: Rule, ruleIndex: number) => (
            <div key={ruleIndex} className="Rules-row">
              <Field
                name={`predictions[${index}].criterias[${criteriaIndex}].rules[${ruleIndex}].property`}
                as={FormikSelectField}
                getOptionLabel={(option: { label: string; value: string }) => option.label}
                getOptionValue={(option: { label: string; value: string }) => option.value}
                options={properties || []}
                customSetFieldValue={(name: string, option: ModelProperty) => {
                  setPropertyFields(ruleIndex, option);
                }}
                errorText={ruleErrors[ruleIndex]?.property}
              />
              <Field
                name={`predictions[${index}].criterias[${criteriaIndex}].rules[${ruleIndex}].constraint`}
                as={FormikSelectField}
                getOptionLabel={(option: { label: string; value: string }) => option.label}
                getOptionValue={(option: { label: string; value: string }) => option.value}
                options={rule?.config?.constraintValues || []}
                customSetFieldValue={(name: string, option: OptionType) => {
                  setFieldValue(name, option.value);
                }}
                errorText={ruleErrors[ruleIndex]?.constraint}
              />
              {rule?.config?.isNumeric === true ? (
                <Field
                  as={FormikInputField}
                  name={`predictions[${index}].criterias[${criteriaIndex}].rules[${ruleIndex}].filterValue`}
                  max={rule.config?.max}
                  min={rule.config?.min}
                  type="number"
                  errorText={ruleErrors[ruleIndex]?.filterValue}
                />
              ) : (
                <Field
                  isCreatable
                  name={`predictions[${index}].criterias[${criteriaIndex}].rules[${ruleIndex}].filterValue`}
                  as={FormikSelectField}
                  getOptionLabel={(option: { label: string; value: string }) => option.label}
                  getOptionValue={(option: { label: string; value: string }) => option.value}
                  options={rule?.config?.filterValues || []}
                  customSetFieldValue={(name: string, option: OptionType) => {
                    setFieldValue(name, option.value);
                  }}
                  errorText={ruleErrors[ruleIndex]?.filterValue}
                />
              )}
              <BtnIcon
                icon="close"
                tooltip={i18n.t('ai:model.deleteRule')}
                onClick={() => deleteRule(ruleHelpers, ruleIndex)}
                testHook="ruleDelete"
                className="h-4 w-4 p-0.5"
              />
            </div>
          ))}
          <BtnOutlined
            className="mt-4 rounded-full"
            icon="add"
            color="amber"
            size="xs"
            testHook="addOr"
            onClick={() =>
              ruleHelpers.push({
                property: '',
                constraint: '',
                filterValue: '',
              })
            }
          >
            {i18n.t('ai:model.addOr')}
          </BtnOutlined>
        </div>
      )}
    />
  );
}

export default RuleForm;
