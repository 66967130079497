import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Field, FormikHelpers, FormikProps } from 'formik';
import i18n from '~/i18n';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import BtnIcon from '~/components/src/BtnIcon';
import { useAPI } from '~/common/ApiHooks';
import Spin from '~/components/src/Spin';
import CheckboxElement from '~/components/src/Form/Elements/CheckboxElement';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';
import { getConnectorDetails } from './dataService';
import { TAirshipTagsFormValues, TAirshipTagsProps } from './types';

const min = 1;
const max = 127;

const validations = (overrideTagGroup: boolean) =>
  Yup.object().shape({
    tagName: Yup.string()
      .min(min, i18n.t('validation:validation.minLength', { min }))
      .max(max, i18n.t('validation:validation.maxLength', { max }))
      .required(i18n.t('validation:validation.required')),
    tagGroup: Yup.string()
      .nullable()
      .when([], {
        is: () => overrideTagGroup,
        then: (schema: Yup.StringSchema) => schema.required(i18n.t('validation:validation.required')),
        otherwise: (schema: Yup.StringSchema) => schema.nullable(),
      }),
  });

const AirshipTags = ({ onEdit, isSubmitted, connector, type, stageName }: TAirshipTagsProps) => {
  const [overrideTagGroup, setOverrideTagGroup] = useState(false);

  const {
    partnerDetails: { partnerId = '', tagGroup: initialTagGroup },
  } = connector;

  const { data: connectorDetails = {}, isLoading: isConnectorDetailsIsLoading } = useAPI(() =>
    getConnectorDetails(partnerId),
  );

  const { configuration } = connectorDetails || {};

  const handleOverrideTagGroup = async (
    setFieldValue: FormikHelpers<TAirshipTagsFormValues>['setFieldValue'],
    validateForm: FormikHelpers<TAirshipTagsFormValues>['validateForm'],
    setFieldTouched: FormikHelpers<TAirshipTagsFormValues>['setFieldTouched'],
  ) => {
    const newOverrideTagGroup = !overrideTagGroup;
    setOverrideTagGroup(newOverrideTagGroup);

    if (!newOverrideTagGroup) {
      await setFieldValue('tagGroup', null);

      // This is to reset the errors on tagGroup when override is unchecked
      await setFieldTouched('tagGroup', false, false);
    }
    validateForm();
  };

  if (isConnectorDetailsIsLoading) {
    return <Spin />;
  }

  return (
    <ConnectorGenericForm
      onEdit={onEdit}
      validations={validations(overrideTagGroup)}
      connector={connector}
      isSubmitted={isSubmitted}
    >
      {({
        values,
        touched,
        errors,
        validateForm,
        setFieldValue,
        setFieldTouched,
      }: FormikProps<TAirshipTagsFormValues>) => {
        useEffect(() => {
          if (initialTagGroup) {
            setOverrideTagGroup(true);
            setFieldValue('tagGroup', initialTagGroup);
          } else {
            setFieldValue('tagGroup', null);
          }
        }, [initialTagGroup]);

        return (
          <div className="flex flex-col gap-4">
            <div className="flex w-1/2 gap-4">
              <Field
                name="tagName"
                as={FormikInputField}
                label={i18n.t('audiences:connectors.airshipTags.tag')}
                infoTooltip={i18n.t('audiences:connectors.airshipTags.tagTooltip')}
                placeholder={i18n.t('audiences:connectors.airshipTags.placeholder')}
                autoFocus={true}
                className="w-1/2 flex-1"
                errorText={touched?.tagName && errors.tagName}
              />
              <BtnIcon
                className="mt-10 p-2"
                testHook="autoFill"
                icon="copy"
                tooltip={i18n.t('audiences:connectors.airshipTags.autoFill')}
                onClick={() => {
                  setFieldValue('tagName', stageName);
                }}
              />
            </div>

            <div>
              <span className="text-gray-600">{i18n.t('partners:form.notification.tagGroup.connectorTagGroup')}: </span>
              <span className="t-connectorTagGroup text-gray-500">{configuration?.tagGroup}</span>
            </div>

            <label className="t-overrideTagGroup inline-flex cursor-pointer items-center gap-3">
              <CheckboxElement
                name="overrideTagGroup"
                value={overrideTagGroup}
                onChange={() => handleOverrideTagGroup(setFieldValue, validateForm, setFieldTouched)}
              />
              <span className="truncate text-ellipsis">
                {i18n.t('partners:form.notification.tagGroup.overideLabel', { type })}
              </span>
            </label>

            {overrideTagGroup && (
              <Field
                name="tagGroup"
                as={FormikInputField}
                label={i18n.t('audiences:connectors.airshipTags.tagGroup')}
                infoTooltip={i18n.t('audiences:connectors.airshipTags.tagGroupTooltip')}
                placeholder={i18n.t('audiences:connectors.airshipTags.placeholder')}
                className="mt-2 w-1/2 flex-1"
                value={values.tagGroup ?? ''}
                autoFocus={true}
                errorText={touched?.tagGroup && errors.tagGroup}
              />
            )}
          </div>
        );
      }}
    </ConnectorGenericForm>
  );
};

export default AirshipTags;
