import { toUpper } from 'lodash';
import { useEffect, useState } from 'react';
import { useAPI } from '~/common';
import { getAllRuleVariables } from '~/common/modules/rules/dataService';
import { TVariable } from './types';

export const useAllVariables = () => {
  const [allVariables, setAllVariables] = useState<TVariable[]>([]);
  const { data, isLoading, error } = useAPI(() => getAllRuleVariables());

  useEffect(() => {
    if (data !== null) {
      setAllVariables(data);
    }
  }, [data]);

  const refetchAllVariables = async () => {
    const refetchedAllVariables = await getAllRuleVariables();
    setAllVariables(refetchedAllVariables);
  };

  return {
    allVariables: allVariables?.map((variable: TVariable) => ({
      ...variable,
      type: toUpper(variable?.variableType?.label || ''),
    })),
    isLoading: isLoading || allVariables === null,
    error,
    refetchAllVariables,
  };
};
