import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { components } from 'react-select';
import { Field, FormSection, InjectedFormProps } from 'redux-form';
import { changeUrl, isRequired, tokenIsRequired, useAPI } from '~/common';
import { Notification } from '~/components';
import Btn from '~/components/src/Btn';
import { I18NextT } from '~/components/src/Common/types';
import i18n from '~/i18n';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormSensitiveField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import Spin from '~/components/src/Spin';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import makeAnimated from 'react-select/animated';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';
import { fetchAccountIDs, fetchAuthorizationUrl, fetchPayloadTypes } from './dataService';
import { LinkedinFormProps, TAccountOption } from './types';

const renderMessage = (t: I18NextT) => (
  <ol className="mt-2">
    <li>{t('form.notification.oAuthMessageStep1', { partner: 'LinkedIn' })}</li>
    <li>{t('form.notification.oAuthMessageStep2')}</li>
    <li>{t('form.notification.oAuthMessageStep3')}</li>
    <li>{t('form.notification.oAuthMessageStep4')}</li>
    <li>{t('form.notification.oAuthMessageStep5')}</li>
  </ol>
);

const setPartnerValues = (change: InjectedFormProps['change']) => {
  const partnerValues = JSON.parse(localStorage.getItem('partnerValues') || '{}');

  if (partnerValues?.name) {
    change('LINKEDIN.name', partnerValues?.name);
  }
  if (partnerValues?.accountId) {
    change('LINKEDIN.accountId', partnerValues?.accountId);
  }
  if (partnerValues?.payloadType) {
    change('LINKEDIN.payloadType', partnerValues?.payloadType);
  }
};

const animatedComponents = makeAnimated();

const Expiry = ({ expiryTimeInMillis, t }: { expiryTimeInMillis: number; t: I18NextT }) => {
  const expiryDate = moment(expiryTimeInMillis).format('DD/MM/YYYY');
  const expiryTime = moment(expiryTimeInMillis).format('HH:mm:ss');

  return (
    <div className="t-expiryTimeInMillis my-2 flex items-baseline gap-1">
      <span>{t('form.formFields.expiresAt')}: </span>
      <span className="text-gray-600">{expiryDate}</span>
      <span className="text-sm text-blue-500">{expiryTime}</span>
    </div>
  );
};

const Option = (props: any) => (
  <div className="MultiSelectColumnFilter-option">
    <components.Option {...props}>
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col gap-1">
          <span>{props.data.name}</span>
          <span className="text-xs text-gray-500">
            {i18n.t('partners:form.formFields.accountId')}: {props.data.accountId || '-'}
          </span>
        </div>
        <span className="text-sm capitalize text-gray-500">{props.data.reference}</span>
      </div>
    </components.Option>
  </div>
);

const LinkedinForm = ({ t, change, touch, values, partner, mode, canUpdate: isEditMode }: LinkedinFormProps) => {
  const [accountIDs, setAccountIDs] = useState<TAccountOption[]>([]);

  const { data: payloadTypes = [], isLoading: isPayloadTypesLoading } = useAPI(() => fetchPayloadTypes());
  const { data: authorizationUrl, isLoading: isAuthUrlLoading } = useAPI(() => fetchAuthorizationUrl());

  const params = new URL(window.location.href).searchParams;
  const refreshToken = params.get(constants.refreshToken);
  const expiryTimeInMillis = Number(params.get('expiryTimeInMillis') || 0) || values.expiryTimeInMillis;
  const error = params.get('error');

  const getAccountIds = async (token: string) => {
    const _accountIds = await fetchAccountIDs(token);
    setAccountIDs(_accountIds || []);
  };

  const redirect = () => {
    localStorage.setItem('partnerValues', JSON.stringify(values));
    changeUrl(authorizationUrl, true, true);
  };

  const handleAccountSelection = (accountId: number) => {
    const selected = accountIDs.find(accountIDs => accountIDs.value === accountId);
    if (selected) {
      change('LINKEDIN.accountId', selected.value);
      change('LINKEDIN.accountName', selected.label);
    }
  };

  useEffect(() => {
    if (refreshToken) {
      change('LINKEDIN.refreshToken', refreshToken);
      change('LINKEDIN.expiryTimeInMillis', expiryTimeInMillis);
      change('LINKEDIN.accountId', ''); // UnSelect Account ID as refresh token is modified
      change('LINKEDIN.accountName', ''); // UnSelect Account ID as refresh token is modified
      getAccountIds(refreshToken);
      setPartnerValues(change);
    }
  }, []);

  useEffect(() => {
    if (values.accountId && !refreshToken) {
      setAccountIDs([{ value: values.accountId, label: values.accountName } as any]);
    }
  }, [values.accountId]);

  if (isPayloadTypesLoading || isAuthUrlLoading || !authorizationUrl || !payloadTypes) return <Spin />;

  const isPartnerNumberFieldVisible =
    mode === constants.modes.CREATE_SERVER &&
    values.payloadType &&
    payloadTypes.length > 0 && // Makes sure payloadTypes are loaded before accessing them
    payloadTypes.find(payloadType => payloadType.value === values.payloadType)?.allowsPartnerNumberOverride;

  return (
    <FormSection name={constants.partnerTypes.LINKEDIN}>
      <FormHeader
        t={t}
        partner={partner}
        title={values.name}
        description={t('form.notification.storeMappingMessage')}
        partnerNumber={values.partnerNumber || partner.partnerNumber}
      />
      <ContainerFormSection
        title={t('form.formFields.refreshToken')}
        description={t('form.notification.oAuthMessage', { partner: 'LinkedIn' })}
        extra={renderMessage(t)}
      >
        <div className="flex flex-col gap-4">
          <div className="flex flex-col items-end justify-center gap-2">
            <Btn
              color="blue"
              onClick={() => redirect()}
              icon="linkedin"
              testHook="linkedinButton"
              className="!py-2 px-3"
              iconClassName="h-7 w-7"
            >
              {t('form.formFields.grantPermissionWithLinkedin')}
            </Btn>
            {error && (
              <Notification className="my-2 w-full" kind="error">
                {error}
              </Notification>
            )}
          </div>
          <FieldWithEditMode
            label={t('form.formFields.refreshToken')}
            name="refreshToken"
            component={ReduxFormSensitiveField}
            isNeedActivateEditMode={isEditMode}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={!isEditMode && tokenIsRequired}
            type="text"
            disabled={true}
          />
          {!!expiryTimeInMillis && <Expiry expiryTimeInMillis={expiryTimeInMillis} t={t} />}
          <Field
            label={t('form.formFields.accountId')}
            name="accountId"
            component={ReduxFormSelectField}
            components={{
              Option,
              animatedComponents,
            }}
            options={accountIDs}
            validate={isRequired}
            disabled={isEditMode || !refreshToken}
            onChange={(event, value) => handleAccountSelection(value)}
            touch={touch}
          />
          <Field
            label={t('form.formFields.payloadType')}
            name="payloadType"
            component={ReduxFormSelectField}
            options={payloadTypes}
            validate={isRequired}
            disabled={isEditMode}
            onChange={(event, value) => {
              change(
                'LINKEDIN.partnerNumber',
                payloadTypes.find(payloadType => payloadType.value === value)?.partnerNumber,
              );
            }}
            touch={touch}
          />
          {isPartnerNumberFieldVisible && (
            <Field
              label={t('form.formFields.partnerNumberLabel')}
              name="partnerNumber"
              component={ReduxFormInputField}
              placeholder={t('form.formFields.inputPlaceHolder')}
              validate={isRequired}
              type="text"
            />
          )}
        </div>
      </ContainerFormSection>
    </FormSection>
  );
};

export default LinkedinForm;
