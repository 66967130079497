const connectorFormActionTypes = {
  CLANG: 'CLANG',
  TRADEDESK: 'TRADEDESK',
  DOUBLE_CLICK: 'DOUBLE_CLICK',
  DOUBLE_CLICK_OFFLINE: 'DOUBLE_CLICK_OFFLINE',
  FACEBOOK: 'FACEBOOK',
  GOOGLE_ADS: 'GOOGLE_ADS',
  YAHOO: 'YAHOO',
  LINKEDIN: 'LINKEDIN',
  APPNEXUS: 'APPNEXUS',
  FETCH_PARTNER_TYPES: {
    pending: 'FETCH_PARTNER_TYPES/pending',
    fulfilled: 'FETCH_PARTNER_TYPES/fulfilled',
    rejected: 'FETCH_PARTNER_TYPES/rejected',
  },
  CREATE_CONNECTOR: {
    pending: 'CREATE_CONNECTOR/pending',
    fulfilled: 'CREATE_CONNECTOR/fulfilled',
    rejected: 'CREATE_CONNECTOR/rejected',
  },
  UPDATE_CONNECTOR: {
    pending: 'UPDATE_CONNECTOR/pending',
    fulfilled: 'UPDATE_CONNECTOR/fulfilled',
    rejected: 'UPDATE_CONNECTOR/rejected',
  },

  SET_PARTNER_TYPES: 'SET_PARTNER_TYPES',
  SET_PARTNER: 'SET_PARTNER',
  CLEAR_CONNECTOR_FORM: 'CLEAR_CONNECTOR_FORM',
};

export default connectorFormActionTypes;
