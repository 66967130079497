angular.module('webUi.service.csv', ['webUi.common.Utils'])

    .factory('CsvService', ['$q', 'HelperService', 'Utils',
        function($q, HelperService, Utils){

            var transformToSafeCsvRow = function(cols, separator = ',') {

                cols = _.map(cols, function(col) {

                    // transform to string
                    if(!_.isString(col)) {
                        col = '' + JSON.stringify(col);
                    }

                    col = Utils.escapeCsvValue(col);
                    // escape all " with "" and wrap in "column"
                    col = col.replace(/"/g, '""');
                    return '"' + col + '"';
                });

                return cols.join(separator);
            };

            var getNewLineCharacter = function() {
                var platform = navigator.platform.toLowerCase();

                if(platform.indexOf('win') !== -1) { // Windows
                    return '\r\n';
                } else if(platform.indexOf('mac') !== -1) { // Mac
                    return '\r';
                } else { // *nix
                    return '\n';
                }
            };

            var service = {

                generateFileName : function(filename, appendDateComponent) {

                    var generatedName = filename;

                    if(appendDateComponent) {
                        generatedName += '_' + moment().format('YYYYMMDD_HHmm');
                    }

                    generatedName += '.csv';

                    return generatedName;
                },

                /**
		 * returns a spinnerWrapper which you can close / show status
		 *
		 * @param exportFile
		 * @param rows
		 * @param rowGenerator
		 * @param header
		 */
                exportAsFile: function(exportFile, rows, rowGenerator, header) {

                    // transform array into generator function
                    if(_.isArray(rowGenerator)) {
                        var columns = rowGenerator;
                        rowGenerator = function(row) {
                            var colData = [];
                            _.forEach(columns, function(col) {
                                if(_.isFunction(col)) {
                                    colData.push(col(row));
                                } else {
                                    colData.push(row[col]);
                                }
                            });

                            return colData;
                        };
                    }

                    var csvContent = '';
                    var newLineCharacter = getNewLineCharacter();

                    if(header && _.isArray(header)) {
                        csvContent += transformToSafeCsvRow(header) + newLineCharacter;
                    }

                    // generate content
                    _.forEach(rows, function(row, index) {

                        csvContent += transformToSafeCsvRow(rowGenerator(row));

                        if(index < rows.length) {
                            csvContent += newLineCharacter;
                        }
                    });

                    // IE 10+
                    if (navigator.msSaveBlob) {
                        var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                        navigator.msSaveBlob(blob, exportFile);
                    } else {
                        // create a for downloading
                        var link = document.createElement('a');

                        // hashtags(#) was broke this import, this why need to replace
                        var encodedCsvContent = encodeURI(csvContent).replace(/#/g, '%23');
                        var encodedUri = 'data:text/csv;charset=utf-8,' + encodedCsvContent;

                        link.setAttribute('href', encodedUri);
                        link.setAttribute('download', exportFile);

                        // browser does not support show message
                        if(!link.download) {
                            HelperService.showBrowserNotSupported();
                            return;
                        }

                        // download
                        document.body.appendChild(link);
                        setTimeout(function() {
                            link.click();
                            document.body.removeChild(link);
                        }, 1);
                    }
                }
            };

            return service;
        }]);

